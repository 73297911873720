@font-face {
  font-family: MuseoSans;
  src: local(MuseoSans-700),
    url(fonts/MuseoSans-700.woff) format("woff"),
    url(fonts/MuseoSans-700.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: MuseoSans;
  src: local(MuseoSans-500), url(fonts/MuseoSans-500.woff2) format("woff2"),
    url(fonts/MuseoSans-500.woff) format("woff"),
    url(fonts/MuseoSans-500.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: MuseoSans;
  src: url(fonts/museosans-900-webfont.woff) format("woff"),
    url(fonts/museosans-900-webfont.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: MuseoSans;
  src: local(MuseoSans-500), url(fonts/MuseoSans-500.woff2) format("woff2"),
    url(fonts/MuseoSans-500.woff) format("woff"),
    url(fonts/MuseoSans-500.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: MuseoSansRounded;
  src: url(fonts/MuseoSansRounded500.otf) format("opentype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "MuseoSans";
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  src: url("./fonts/museo_sans_300/MuseoSans300Regular.eot");
  src: url("./fonts/museo_sans_300/MuseoSans300Regular.eot") format("embedded-opentype"),
    url("./fonts/museo_sans_300/MuseoSans300Regular.woff2") format("woff2"),
    url("./fonts/museo_sans_300/MuseoSans300Regular.woff") format("woff"),
    url("./fonts/museo_sans_300/MuseoSans300Regular.ttf") format("truetype");
}

:root {
  --white-color: #ffffff;
  --corpo-color: #dc0740;
  --white_b-color: #dfe1e5;
  --blue-color: #2b74c4;
  --blackh1-color: #2d2f30;
  --grey-color: #f4f5f7;
  --home-color: #383b41;
  --footer-color: #232322;
  --grey-ind-color: #c1c7d0;
  --grey-dark: #818181;
  --grey-disabled: #c3c3c3;
  --blue-hover: #2462a6;
  --error-color: #e66565;
  --border-color: #a5adbb;
  --primary-color: #CA1141;
  --primary-hover: #990f34;
  --dark-color: #575756;
  --gris-color: #AAAAAA;
  --silver-color: #F5F5F5;
  --blue-new-color: #1E7BBF;
  --green-light-color: #81C187;
  --orange-color: #EF8025;
  --violet-color: #A868A7;
}


.bg-white {
  background-color: var(--white-color);
}

.headerModal {
  background-color: var(--corpo-color) !important;
  color: white !important;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.marginerror {
  margin-right: 15px;
}

.errorLayer {
  margin-top: 3px;
  font-size: 0.66em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.2px;
  color: var(--error-color);
}

.btnHomeFont {
  font-size: 0.66em !important;
}

.successLayer {
  margin-top: 3px;
  font-size: 0.66em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.2px;
  color: var(--error-color);
}

.infoLayer {
  margin-top: 3px;
  font-size: 1.15em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.2px;
  color: var(--home-color);
}

.hPaddingCarrusel {
  padding: 0px !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.MuiGrid-spacing-xs-3 {
  width: 100% !important;
  margin: 0px !important;
}

ol.carousel-indicators {
  position: absolute;
  bottom: 0;
  margin: 0;
  left: 0;
  right: 0;
  width: auto;
}

.text-center {
  text-align: center;
}

.text-start {
  text-align: start !important;
}

.text-end {
  text-align: end;
}

.text-bold {
  font-weight: 700;
}

.text-upper {
  text-transform: uppercase;
}

ol.carousel-indicators li,
ol.carousel-indicators li.active {
  float: left;
  width: 8px;
  height: 8px;
  margin: 8px;
  border-radius: 50%;
  border: 0;
  background: transparent;
}

ol.carousel-indicators li {
  background: var(--grey-ind-color);
}

ol.carousel-indicators li.active {
  background: var(--blue-color);
}

.fullPage {
  min-height: 93vh;
}

.fullPageNew {
  min-height: 93vh;
  background-color: var(--silver-color);
  color: var(--dark-color);
}

/* TODO: me parece mala practica que sea global y tengo especificacion de clases, cambiar si alcanza tiempo */
.fullPageNew a {
  color: var(--primary-color);
  text-decoration: underline;
}

.fullPageNew a:hover {
  color: var(--primary-hover);
  text-decoration: underline;
}

.fullPageNew .defaultFull {
  padding-bottom: 120px;
}

div.defaultFullSpace {
  margin-top: 30px !important;
}

body {
  overflow-x: auto !important;
  font-family: MuseoSans, sans-serif !important;
  background-color: var(--white-color) !important;
  background-color: #ffffff !important;
}

.footerbg {
  height: 110px !important;
  background-color: var(--footer-color) !important;
  bottom: 0;
  width: 100%;
}

.whiteLinkNoHover {
  font-size: 0.88em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: var(--white-color);
  margin-left: 35px !important;
}

.whiteLinkNoHover:hover {
  font-size: 0.88em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: var(--white-color);
  margin-left: 35px !important;
}

.whiteLinkNoHover:visited {
  font-size: 0.88em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: var(--white-color);
  margin-left: 35px !important;
}

.ant-menu-item-active {
  /* border-bottom: solid 2px var(--white-color) !important; */
}

.whiteLink :hover {
  font-size: 0.88em !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: 0.5px !important;
  color: var(--white-color) !important;
  margin-left: 35px !important;
}

.whiteLink:hover {
  font-size: 0.88em !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: 0.5px !important;
  color: var(--white-color) !important;
  border-bottom: solid 2px var(--white-color) !important;
}

.whiteLink:visited {
  font-size: 0.88em !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: 0.5px !important;
  color: var(--white-color) !important;
  border-bottom: solid 2px var(--white-color) !important;
}

.whiteLink:active {
  font-size: 0.88em !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: 0.5px !important;
  color: var(--white-color) !important;
  border-bottom: solid 2px var(--white-color) !important;
}

.whiteLinkCooki {
  font-size: 0.88em;
  font-weight: 500;
  color: var(--white-color);
}

.whiteLinkCooki:hover {
  font-size: 0.88em;
  font-weight: 500;
  color: var(--white-color);
  border-bottom: solid 2px var(--white-color);
}

.whiteLinkCooki:visited {
  font-size: 0.88em;
  font-weight: 500;
  color: var(--white-color);
  border-bottom: solid 2px var(--white-color);
}

.whiteLinkCooki:active {
  font-size: 0.88em;
  font-weight: 500;
  color: var(--white-color);
  border-bottom: solid 2px var(--white-color);
}

.imgCarrusel {
  height: 367px !important;
}

.corpoColor {
  color: var(--corpo-color) !important;
}

.bgCorpo {
  background-color: var(--corpo-color) !important;
}

.navbarhome {
  /*  width: 100% !important;
    margin-left: 80px !important; */
  margin-right: 15px;
}

.menuDrawer {
  color: var(--white-color) !important;
  background-color: var(--corpo-color);
  display: none;
  font-size: 0.88em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: center;
}

.navPadding {
  padding-right: 5px;
}

.logoHeader {
  margin-left: 60px;
  padding-top: 10px;
}

.logoHeader img {
  width: 210px;
}

.marginLeftNav {
  margin-left: 25px;
}

.more {
  margin-left: 50px !important;
}

.buttonNewsletter {
  padding-top: 16px;
  width: 240px;
  height: 50px !important;
  color: var(--corpo-color) !important;
}

.buttonNewsletter:hover {
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.07);
  background-color: var(--grey-ind-color) !important;
  color: var(--corpo-color) !important;
}

.buttonRegistroNav {
  text-transform: uppercase;
  height: 30px;
  border-radius: 90px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.07);
  background-color: var(--white-color);
  font-size: 0.77em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.5px;
  text-align: center;
  color: var(--blue-color);
}

.buttonRegistroNav:hover {
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.07);
  background-color: var(--blue-color);
  color: var(--white-color);
}

.mainLink {
  font-size: 0.77em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.5px;
  text-align: right;
  text-decoration: underline !important;
  color: var(--blue-color) !important;
}

.mainLink:hover {
  font-size: 0.77em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.5px;
  text-align: right;
  text-decoration: underline !important;
  color: var(--blue-hover) !important;
}

.buttonAbirRecursos {
  text-align: center;
  height: 40px !important;
  text-transform: uppercase !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.07) !important;
  font-size: 0.77em !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1 !important;
  letter-spacing: 0.5px !important;
  text-align: center !important;
}

.maindarkMenu {
  font-size: 0.83em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-decoration: none;
  color: var(--home-color);
}

.maindarkMenu:active {
  font-size: 0.83em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;

  text-decoration: underline;
  color: var(--blackh1-color);
}

.maindarkMenu:visited {
  font-size: 0.83em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;

  text-decoration: underline;
  color: var(--blackh1-color);
}

.maindarkMenu:hover {
  font-size: 0.83em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;

  text-decoration: underline;
  color: var(--blackh1-color);
}

.maindarkLink {
  font-size: 0.88em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-decoration: underline;
  color: var(--home-color);
}

.loginSpaceHr {
  margin-top: 37px;
  margin-bottom: 37px;
  width: 100%;
  background: var(--silver-color);
}

.maindarkLinkContent {
  margin-bottom: 32px;
}

.maindarkLink:hover {
  font-size: 0.88em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;

  text-decoration: underline;
  color: var(--blackh1-color);
}

.navHeader {
  /*position: fixed;*/
  width: 100%;
  box-shadow: 0 2px 4px 0 var(--white_b-color);
  z-index: 1;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*padding: 0px 100px 0;*/
  height: 100px;
  background: var(--corpo-color);
}

.appHeader {
  background: var(--corpo-color) !important;
  box-shadow: none !important;
}

.h1home {
  font-size: 2.875em !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--blackh1-color);
}

.h1homeNew {
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 112.5%;
  letter-spacing: normal;
  color: var(--dark-color);
  margin-bottom: 8px;
}

.h1homeNewLimitWidth {
  max-width: 309px;
}

.h1homeRegister {
  font-size: 36px;
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 60px;
  /* margin-bottom: calc( 30px + (60 - 30) * ((100vw - 320px) / (1280 - 320))); */
}

.h1link {
  font-size: 1.3em !important;
  font-weight: bold;
}

.h1link:hover {
  font-size: 1.3em !important;
  font-weight: bold;
}

.h2home {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--dark-color);
  margin-bottom: 18px;
}

.bgWhite {
  background-color: var(--white-color) !important;
}

.bgGrey {
  background-color: var(--grey-color) !important;
}

.cornerHomeLeft {
  border-bottom-left-radius: 100px !important;
}

.cornerHomeRight {
  border-bottom-right-radius: 100px !important;
}

.homeContainer {
  padding-left: 26px;
  padding-right: 26px;
  padding-top: 50px;
  padding-bottom: 90px;
}

.homeContainerXcelence {
  padding-top: 20px !important;
  padding-bottom: 40px !important;
}

.homeCarrusel {
  height: 433px !important;
  padding-left: 90px;
  padding-right: 90px;
}

.subh1home {
  font-size: 1.375em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  color: var(--blackh1-color);
}

.subh1homeNew {
  color: var(--dark-color);
  margin-bottom: 14px;
  font-size: 16px;
  line-height: 1.5;
}

.subh1homelink {
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  color: var(--blue-color);
}

.subh1homelink:hover {
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  color: var(--blue-color);
  text-decoration: underline;
}

.txtHome {
  font-size: 1.15em;
  text-align: justify;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: var(--home-color);
}

.txtHomeNew {
  font-size: 16px;
  text-align: justify;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.50;
  letter-spacing: normal;
  color: var(--dark-color);
  margin-bottom: 32px;
  text-align: start;
}

.txtHomeNewNoSpace {
  margin-bottom: 20px;
}

.txtHomelink {
  font-size: 1.15em;
  text-align: justify;

  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: var(--blue-color);
}

.txtHomelink:hover {
  font-size: 1.15em;
  text-align: justify;

  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: var(--blue-color);
  text-decoration: underline;
}

.txtYear {
  text-align: center;
  color: var(--corpo-color);
}

.txtgrey {
  color: var(--grey-dark);
}

.txtHomeStrong {
  margin-top: 10px;
  font-weight: 600;
}

.txtHomeClaves {
  font-size: 0.77em;
}

.posClaveL {
  margin-top: 140px;
}

.txtAdvertencia {
  font-size: 0.83em;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #e66565 !important;
}

.padBottomHome {
  padding-bottom: 14px;
}

.padBottomHomeMore {
  padding-bottom: 35px;
}

.paddingCenter {
  padding-top: 75px !important;
}

.modalAdvertenciaSection {
  background-color: #f7f7f7;
  width: 570px;
  height: 49px;
}

.btnCenterResponsiveAdvertencia {
  margin-top: 40px;
}

.btnFichaDesc {
  padding-top: 16px !important;
  width: 260px;
  height: 50px;
  text-transform: uppercase !important;
  border-radius: 90px !important;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.07) !important;
  font-size: 0.77em !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1 !important;
  letter-spacing: 0.5px !important;
  text-align: center !important;
  color: var(--white-color) !important;
}

.padLeft {
  padding-left: 50px !important;
}

.btnRemove {
  height: 50px !important;
  width: 450px !important;
  padding-top: 13px !important;
  text-transform: uppercase !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.07) !important;
  background-color: var(--grey-color) !important;
  font-size: 0.77em !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1 !important;
  letter-spacing: 0.5px !important;
  text-align: center !important;
  color: var(--blue-color) !important;
  border: solid 2px var(--blue-color) !important;
}

.btnRemove:hover {
  background-color: var(--grey-ind-color) !important;
}





.btnHomeSup {
  height: 50px !important;
  width: 200px !important;
  padding-top: 16px !important;
  text-transform: uppercase !important;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.07) !important;
  background-color: var(--blue-color) !important;
  font-size: 0.77em !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1 !important;
  letter-spacing: 0.5px !important;
  text-align: center !important;
  color: var(--white-color) !important;
}

.btnHomeSup:hover {
  background-color: var(--blue-hover) !important;

}



.btnHomeSup2 {
  height: 50px !important;
  width: 200px !important;
  padding-top: 16px !important;
  text-transform: uppercase !important;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.07) !important;
  background-color: #d8e3f5 !important;
  font-size: 0.77em !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1 !important;
  letter-spacing: 0.5px !important;
  text-align: center !important;
  color: var(--black-h1-color) !important;
}

.btnHomeSup2:hover {
  background-color: #e6e8eb !important;

}

.sindatos {
  position: relative;
  margin: auto;
  left: 30%;
  top: 210px;
  font-size: 1.3em !important;
  font-weight: bold !important;

}

.btnRegisterMenu {
  display: inline-flex;
  gap: 5px;
  align-items: flex-start;
  background-color: white !important;
  text-align: center;
  border-radius: 90px;
  color: var(--blue-color) !important;
  min-width: 120px;
  padding: 10px 1rem;
  margin: 0 0.3rem;
}

.deactiveButton2 {
  height: 50px !important;
  width: 200px !important;
  padding-top: 10px !important;
  text-transform: uppercase !important;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.07) !important;
  background-color: var(--white-color) !important;
  font-size: 0.77em !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1 !important;
  letter-spacing: 0.5px !important;
  text-align: center !important;
  color: var(--black-h1-color) !important;
}


.deactiveButton {
  height: 50px !important;
  width: 200px !important;
  padding-top: 10px !important;
  text-transform: uppercase !important;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.07) !important;
  background-color: var(--grey-disabled) !important;
  font-size: 0.77em !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1 !important;
  letter-spacing: 0.5px !important;
  text-align: center !important;
  color: var(--white-color) !important;
}

.greytext {
  color: var(--grey-dark) !important;
  font-size: 0.75em !important;
}


.btnHomeReverseBorder {
  height: 50px !important;
  width: 450px !important;
  padding-top: 16px !important;
  text-transform: uppercase !important;
  border-radius: 90px !important;

  border: 3px solid var(--blue-color) !important;
  font-size: 0.77em !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1 !important;
  letter-spacing: 0.5px !important;
  text-align: center !important;
  color: var(--blue-color) !important;
  background-color: var(--white-color) !important;
}

.btnHomeReverseBorder:hover {
  border: 3px solid var(--blue-color) !important;
  background-color: var(--blue-color) !important;
  color: var(--white-color) !important;
}

.btnNew,
button.btnNew {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 6.5px 16px;
  font-size: 14px;
  line-height: 125%;
  border-radius: 100px;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.07);
  width: auto;
  max-width: min-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  letter-spacing: 0.42px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

a.btnNew {
  text-decoration: none;
}

a.btnNew:hover {
  text-decoration: none;
}

.btnHome {
  height: 50px !important;
  width: 450px !important;
  padding-top: 16px !important;
  text-transform: uppercase !important;
  border-radius: 90px !important;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.07) !important;
  background-color: var(--blue-color) !important;
  font-size: 0.77em !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1 !important;
  letter-spacing: 0.5px !important;
  text-align: center !important;
  color: var(--white-color) !important;
}

.btnHome:hover {
  background-color: var(--blue-hover) !important;
}

.signupBox {
  text-align: start;
}

.signupStep {
  font-size: 24px;
  /* position: inherit; */
  display: flex;
  align-content: center;
  justify-content: start;
  gap: 10px;
  margin-bottom: 12px;
}

.signupStep::before {
  content: '';
  background-color: var(--blue-color);
  width: 24px;
  height: 24px;
  border-radius: 100px;
  display: inline-flex;

}

.signupStep p {
  margin-bottom: 0;
  line-height: 1;
}

.signupHr {
  width: 100%;
  border: var(--silver-color) 2px solid;
  margin-top: 32px;
  margin-bottom: 32px;
  border-radius: 4px;
}

button.btnNewRegister {
  margin-left: auto;
  margin-right: auto;
}

.btnNewOutlinePrimary,
a.btnNewOutlinePrimary,
button.btnNewOutlinePrimary {
  background-color: var(--white-color);
  color: var(--primary-color);
  border: 2px var(--primary-color) solid;
}

.btnNewOutlinePrimary:hover,
a.btnNewOutlinePrimary:hover,
button.btnNewOutlinePrimary:hover {
  background-color: var(--primary-hover);
  color: var(--white-color);
  border-color: var(--primary-hover);
}

.btnNewPrimary,
a.btnNewPrimary,
button.btnNewPrimary {
  background-color: var(--primary-color);
  color: var(--white-color);
}

.btnNewPrimary:hover,
a.btnNewPrimary:hover,
button.btnNewPrimary:hover {
  background-color: var(--primary-hover);
  color: var(--white-color);
}

.iconAngleRight {
  margin-left: 8px;
  font-size: 16px;
}

.btnPreguntasModal {
  height: 35px !important;
  width: 200px !important;
  padding-top: 13px !important;
  text-transform: uppercase !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.07) !important;
  background-color: var(--blue-color) !important;
  font-size: 0.77em !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1 !important;
  letter-spacing: 0.5px !important;
  text-align: center !important;
  color: var(--white-color) !important;
}

.btnPreguntasModal:hover {
  background-color: var(--blue-hover) !important;
}

.btnMenu {
  width: 220px !important;
  font-size: 0.72em !important;
  padding-top: 15px !important;
}

.btnBoard {
  width: 220px !important;
  font-size: 0.72em !important;
  padding-top: 12px !important;
}

.btnHomePadding {
  padding-top: 10px !important;
}

.modalborrar {
  width: 180px !important;
  font-size: 0.72em !important;
}

.iconHeadHover:hover {
  background-color: inherit !important;
}

.iconHover:hover {
  background-color: var(--white-color);
  color: var(--blue-color);
}

.btnInside {
  margin: 0 !important;
  margin-right: 69px;
  left: 14px;
  padding: 15px !important;
  width: 110px;
  background-color: var(--blue-color) !important;
  font-size: 0.66em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.29px;
  text-align: center;
  color: var(--white-color) !important;
}

.btnInside:hover {
  background-color: var(--blue-hover) !important;
}


.btnHomeReverse {
  height: 50px !important;
  width: 450px !important;
  padding-top: 15px !important;
  text-transform: uppercase !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.07) !important;
  background-color: var(--white-color) !important;
  font-size: 0.77em !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1 !important;
  letter-spacing: 1.5px !important;
  text-align: center !important;
  color: var(--blue-color) !important;
}

.btnHomeReverse:hover {
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.07) !important;
  background-color: var(--blue-color) !important;
  color: var(--white-color) !important;
}

.footerLink {
  font-size: 0.88em !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: 0.5px !important;
  color: #ffffff !important;
  text-transform: initial !important;
}

.footerLink:hover {
  font-size: 0.88em !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: 0.5px !important;
  color: #ffffff !important;
  text-decoration: underline !important;
}

.fontWhite {
  color: var(--white-color);
}

.marginSt {
  margin-top: 50px;
}

.marginSubSt {
  margin-top: 30px;
}

.logoBertelsHome {
  height: 75px;
  margin-top: 25px;
}

.topCarruselClaves {
  margin-top: -60px !important;
}

.homeContainerLogo {
  height: 200px !important;
  padding-top: 10px !important;
  text-align: center;
}

.homeContainerCarrusel {
  padding-right: 26px !important;
  padding-left: 26px !important;
  padding-top: 15px !important;
}

.homeContainerNewsletter {
  height: 220px !important;
  padding-top: 10px !important;
  text-align: center;
}

.alignCenterO {
  text-align: center;
}

.checkblue {
  text-align: right;
  padding-right: 15px;
}

.showbars {
  color: var(--white-color) !important;
  background-color: var(--corpo-color);
  display: inline-block !important;
  position: absolute !important;
  top: 35px !important;
  right: 20px !important;
  cursor: pointer !important;
}

ul.is-expandedTool {
  z-index: 1000;
  overflow: hidden;
  max-height: 500px;
  /* approximate max height */
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

ul.collapsedTool {
  background: var(--corpo-color);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  position: absolute;
  top: 90px !important;
  right: 0px;
  overflow: hidden;
  max-height: 0;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.menuDrawerTool {
  display: inline-block;
  position: absolute;
  top: 35px;
  right: 50px;
  cursor: pointer;
}

.imRecurso {
  width: 360px;
  margin-top: -30px
}

.fa-bars {
  color: var(--white-color) !important;
  background-color: var(--corpo-color);
  display: none;
}

.noCenter {
  text-align: left !important;
}

.imgCaruselHomeResponsive {
  width: 100%;
  display: none;
}

.videoCeapHome {
  margin: auto !important;
  width: 485px !important;
  height: 273px !important;
}

.videoHomeResponsive {
  width: 100%;
  display: none;
}


.videoHomeNoResponsive {
  display: inline-block;
}

.imgCaruselHomeNoResponsive {
  display: inline-block;
}

.imgRecursosHome {
  display: inline-block;
}

.hideTablet {
  display: inline-block;
}

.sNoResponsive {
  display: inline-block;
}

.sResponsive {
  display: none;
}

.menuResponsive {
  display: none;
}

.menuResponsiveInv {
  display: inline;
}

.slider {
  height: 200px;
}

.menu-item-scroll {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--grey-dark);
  text-transform: uppercase;
  position: relative;
}

.menu-item-scroll {
  width: 140px;
  height: 140px;
  margin-right: 40px;
}

.menu-item-scroll-title {
  font-size: 1em;
  margin-bottom: 5px;
}

.menu-item-scroll-name {
  font-size: 18px;
  position: absolute;
  top: 20px;
  width: 140px;
  height: 140px;
  white-space: pre-wrap;
  /* CSS3 */
  white-space: -moz-pre-wrap;
  /* Firefox */
  white-space: -pre-wrap;
  /* Opera <7 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
}

.menu-item-img {
  position: absolute;
  left: 16%;
}

.estrella {
  width: 100px;
  height: 100px;
}

/*
.carousel-control-next-icon:after {
    content: ">";
    font-size: 55px;
    color: var(--blue-color);
  }
  
  .carousel-control-prev-icon:after {
    content: ">";
    font-size: 55px;
    color: var(--blue-color);
  }*/

.carousel-control-next-icon {
  z-index: 0;
  display: inline-block;
  border-right: 7px solid var(--blue-color);
  border-bottom: 7px solid var(--blue-color);
  width: 30px;
  height: 30px;
  transform: rotate(-45deg);
  background-image: none;
}

.carousel-control-prev-icon {
  z-index: 0;
  display: inline-block;
  border-right: 7px solid var(--blue-color);
  border-bottom: 7px solid var(--blue-color);
  width: 30px;
  height: 30px;
  transform: rotate(-225deg);
  background-image: none;
}

.carousel-control-next,
.carousel-control-prev {
  width: 5%;
  z-index: 0;
}

.carousel-control-prev {
  margin-left: 15px;
}

.carousel-control-next {
  margin-right: 15px;
}

.arrow-prevc {
  z-index: 0;
  display: inline-block;
  border-right: 4px solid var(--corpo-color);
  border-bottom: 4px solid var(--corpo-color);
  width: 20px;
  height: 20px;
  transform: rotate(-225deg);
}

.arrow-nextc {
  z-index: 0;
  display: inline-block;
  border-right: 4px solid var(--corpo-color);
  border-bottom: 4px solid var(--corpo-color);
  width: 20px;
  height: 20px;
  transform: rotate(-45deg);
}

.arrow-prev {
  z-index: 0;
  display: inline-block;
  border-right: 4px solid var(--blue-color);
  border-bottom: 4px solid var(--blue-color);
  width: 30px;
  height: 30px;
  transform: rotate(-225deg);
}

.arrow-next {
  z-index: 0;
  display: inline-block;
  border-right: 4px solid var(--blue-color);
  border-bottom: 4px solid var(--blue-color);
  width: 30px;
  height: 30px;
  transform: rotate(-45deg);
}

.closeWindow {
  width: 54px;
  cursor: pointer;
}

.alignJustify {
  text-align: justify;
}

.privacityp,
div.MuiGrid-item.privacityp {
  padding-left: 272px;
  padding-right: 272px;
  z-index: 0;
}

.textPrivacity {
  font-size: 1em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--home-color);
  word-wrap: break-word;
}

.defaultgrid {
  padding-left: 190px !important;
  padding-right: 120px !important;
}

.defaultgridNew {
  padding-left: 44px;
  padding-right: 44px;
}

.defaultgridLogin {
  max-width: 975px;
  margin-right: auto !important;
  margin-left: auto !important;
}

.defaultFullNew {
  padding-left: 24px;
  padding-right: 24px;
}
.defaultFull {
  min-height: 100% !important;
  align-items: normal !important;
  margin-top: 70px !important;
  padding-bottom: 1px;
}

.linkpriv {
  float: right !important;
  margin: 0;
  padding: 15px 0;
  font-size: 0.77em;
}

.marginSubTitle {
  margin-top: 32px;
}

.marginSubText {
  margin-top: 56px;
}

.nofull {
  width: 220px !important;
}

.strong {
  font-weight: bold;
}

.marginGrid {
  margin-top: 30px;
}

.marginGrid2 {
  margin-top: 30px;
}

.rightd {
  float: right !important;
}

.obtext {
  margin: 0;
  padding: 4px 0;
  font-size: 0.66em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--home-color);
}

.obtextNew {
  font-size: 10px;
  line-height: 1.8;
  letter-spacing: 0.7px !important;
  color: var(--gris-color);
}

.linkAutor {
  width: 172px;
  height: 16px;
  font-family: MuseoSans;
  font-size: 0.88em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: right;
  color: #565858;

  text-decoration: underline;
}

.linkAutor:hover {
  width: 172px;
  height: 16px;
  font-family: MuseoSans;
  font-size: 0.88em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: right;
  color: #565858;
  text-decoration: underline;
}

.formTextNew {
  font-size: 10px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.80;
  letter-spacing: 0.7 !important;
  color: var(--gris-color);
  text-transform: uppercase;
}

.inputMargin {
  margin-top: 5px !important;
}

.widthF {
  width: 370px !important;
}

.uppercase {
  text-transform: uppercase;
}

.inputTransform,
.MuiFormControl-root.inputTransform {
  margin-top: 2px;
  margin-bottom: 20px;
  width: 100%;
}

.inputTransformAutocomplete .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
  padding: 0;
}
.inputTransformAutocomplete .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input,
.inputTransformAutocomplete .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child {
  padding: 12px 16px;
}

.inputTransformAutocomplete.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding-right: 0;
}

/* .inputTransform .MuiSvgIcon-root path {
  stroke: var(--dark-color);
} */

.inputTransform .MuiOutlinedInput-multiline {
  padding: 0;
}

.inputTransform .MuiOutlinedInput-root {
  border: none;
  border-radius: 12px;
}

.inputTransform .MuiInputBase-root {
  color: var(--dark-color);
}

.inputTransform .MuiInputBase-input::placeholder {
  color: var(--gris-color);
}

.inputTransform .MuiOutlinedInput-input {
  padding: 12px 16px;
}

.inputTransform .MuiInputBase-input {
  background-color: var(--silver-color);
  border-radius: 8px;
  font-size: 14px;
}

.inputTransform .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
  border-radius: 6px;
}
div.inputTransform:hover .MuiOutlinedInput-notchedOutline,
.inputTransform:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--dark-color);
}

.inputTransform .Mui-focused .MuiOutlinedInput-notchedOutline,
div.inputTransform .Mui-focused .MuiOutlinedInput-notchedOutline,
div.inputTransform.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--dark-color);;
}
.btnDisabled {
  height: 50px !important;
  width: 450px !important;
  padding-top: 10px !important;
  text-transform: uppercase !important;
  border-radius: 90px !important;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.07) !important;
  background-color: var(--grey-disabled) !important;
  font-size: 0.77em !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1 !important;
  letter-spacing: 0.5px !important;
  text-align: center !important;
  color: var(--white-color) !important;
}

.btnDisabledTransform {
  padding-top: 10px !important;
  background-color: var(--grey-disabled) !important;
  color: var(--white-color) !important;
}

.btnDisabledNew {
  /* box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.07) !important; */
  background-color: var(--grey-disabled) !important;
  color: var(--white-color) !important;
}

.registerWidth {
  height: 50px !important;
  width: 370px !important;
}

.btnCenter {
  font-size: 14px !important;
  padding-top: 8px !important;
  margin: 0 auto !important;
}

.borderPuntua {
  padding-right: 20px;
  border-right: 3px dashed var(--grey-dark);
}

.borderPuntuaArriba {
  border-top: 3px dashed var(--grey-dark);
}

.yesmobile {
  display: none;
}

.passwordField input[type="password"] {
  /*letter-spacing: 2px !important;*/
  /*font-size: 20px !important;*/
}

.centerLoading {
  margin-top: -12 !important;
  margin-left: -200px !important;
}

.centerForm {
  margin-left: -100px !important;
}

.labelForm {
  font-size: 0.88em;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--home-color);
}

.labelFormNew .MuiSvgIcon-root {
  width: 21.4px;
}

.labelFormNew .MuiSvgIcon-root path {
  fill: var(--gris-color);
}

.labelFormNew {
  margin-bottom: 22px;
}

.labelFormNewSmallSpace {
  margin-bottom: 10px;
}

.labelFormNew,
.labelFormNew .MuiFormControlLabel-label {
  font-size: 14px;
  color: var(--gris-color);
  line-height: 1.25;
  font-weight: 500;
  /* solo para check field */
}

.widthContact {
  width: 175px !important;
}

.widthContactForm {
  width: 420px !important;
}

.btnFullButton {
  width: 495px !important;
}

.imgHomeXcelence {
  width: 400px;
}

.itemCaruselCeap {
  width: 600px !important;
  margin: auto !important;
}

.videoceap {
  width: 385px !important;
}

.imgHomeComo {
  width: 600px;
}

.imgCaruselCeap {
  width: 200px !important;
}

.imgHomeVideo {
  margin-left: 50px;
  width: 550px;
  height: 400px;
}

.imgHome {
  width: 550px;
}

.imgCenter {
  text-align: center !important;
}

.paddingRight {
  padding-right: 25px !important;
}

.rounded {
  border-radius: 40px !important;
}

.textLeft {
  text-align: left !important;
}

.Oval {
  width: 136px;
  height: 136px;
  border-radius: 70px;
  padding-top: 40px;
  box-shadow: 0 2px 0 0 rgba(127, 136, 151, 0.19);
  font-size: 1em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 2px;
  color: #ffffff;
  text-transform: uppercase;
}

.OvalCl {
  width: 136px;
  height: 136px;
  border-radius: 70px;
  padding-top: 40px;
  box-shadow: 0 2px 0 0 rgba(127, 136, 151, 0.19);
  font-size: 1em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 2px;
  color: #ffffff;
  text-transform: uppercase;
}

.claveNum {
  font-size: 48px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1 !important;
  letter-spacing: normal !important;
  color: #ffffff !important;
}

.carruselClaves {
  width: calc(100% - 240px) !important;
  margin-left: 130px !important;
}

.beneficio {
  height: 80px;
}

.arrow-next {
  cursor: pointer;
}

.arrow-prev {
  cursor: pointer;
}

.carousel-control-next {
  cursor: pointer;
}

.carousel-control-prev {
  cursor: pointer;
}

.imgTestimonio {
  float: left;
  margin-right: 10px !important;
  width: 180px !important;
  border-radius: 24px !important;
}

.fontStrong {
  font-weight: 700 !important;
}

.ucase {
  text-transform: uppercase;
}

.menuSeparator {
  width: 228px;
  height: 2px;
  background-color: #ebecf0;
}

.menuSeparatorFull {
  height: 2px;
  background-color: #ebecf0;
}

.padTestimonio {
  padding-bottom: 20px !important;
}

.txtTestimonio {
  font-size: 0.66em !important;
}

.imgRosco {
  width: 600px;
}

.modalOnBoard {
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -350px;
  margin-top: -240px;
  width: 700px;
  /* Full width */
  height: 660px;
  /* Full height */
  border-radius: 4px;
  box-shadow: 0 2px 16px 0 #c1c7d0;
  border: solid 1px var(--white_b-color);
  background-color: var(--white-color);
}

.modalOnBoardCompletado {
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -350px;
  margin-top: -190px;
  width: 710px;
  /* Full width */
  height: 489px;
  /* Full height */
  border-radius: 4px;
  box-shadow: 0 2px 16px 0 #c1c7d0;
  border: solid 1px var(--white_b-color);
  background-color: var(--white-color);
}

.modalViewVideo {
  margin: auto;
  margin-top: 100px;
  width: 640px;
  height: 360px;

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  border: solid 1px var(--grey-color);
  background-color: var(--grey-color);
}

.modalvideo {
  z-index: 0;
  /* Sit on top */

  margin: auto;
  position: absolute;
  left: 0%;
  top: 100px;
  width: 100%;
  /* Full width */
  height: 84%;
  /* Full height */
  border: solid 1px var(--blackh1-color);
  background-color: var(--blackh1-color);
}

.underVideo {
  position: absolute;
  left: 50%;
  top: 80%;
  margin-left: -250px;
  margin-top: 10px;
}

.centerModal {
  margin: 50px;
}

.fullHeight {
  margin-top: 20px !important;
  height: 100%;
  display: inherit !important;
}

.claveindicador {
  width: 33px;
  height: 17px;
  border-radius: 8.5px;
  background-color: var(--grey-disabled);
}

.totalClaves {
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--blue-color);
}

.showIndicadores {
  justify-content: center;
  display: flex;
  margin-top: 20px;
  padding: 0;
}

.withMargin {
  margin-top: 30px;
}

.claveIndicadores {
  list-style-type: none;

  overflow: hidden;
}

.claveIndicadores li {
  float: left;
  width: 33px;
  height: 8px !important;
  border-radius: 8.5px;
  background-color: var(--grey-disabled);
  margin-left: 5px;
}

.claveIndicadores li.active {
  float: left;
  width: 33px;
  height: 8px !important;
  border-radius: 8.5px;
  background-color: var(--blue-color);
  margin-left: 5px;
}

.titleRanking {
  padding-bottom: 5px;
  border-bottom: solid 2px var(--white_b-color);
  margin-bottom: 11px;
  cursor: pointer;
  background: url(./close.svg) no-repeat center right;
}

.showallccaa {
  z-index: 10000;
  padding-top: 30px;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 20px;
  position: absolute;
  margin-top: -50px;
  width: 298px;
  height: 764px;
  border-radius: 8px;
  box-shadow: 0 2px 16px 0 #c1c7d0;
  border: solid 1px var(--white_b-color);
  background-color: #ffffff;
}

.menuDirector {
  z-index: 1400;
  padding-top: 30px;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 20px;
  position: absolute;
  margin-top: 15px;
  left: calc(100% - 294px) !important;
  width: 268px;
  height: 668px;
  border-radius: 8px;
  box-shadow: 0 2px 16px 0 #c1c7d0;
  border: solid 1px var(--white_b-color);
  background-color: #ffffff;
}

.titleOnBoard {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
}

.subtitleOnBoard {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 1em;

  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
}

.listHistorico {
  width: calc(100% - 10px);
  height: 100%;
  background-color: var(--white-color);
  margin-top: 30px;
  border: solid 10px var(--grey-color);

  padding-bottom: 10px;
}

.showList {
  height: 55vh;
  position: relative;
  overflow: auto;
}

.formatDate {
  color: var(--blue-color) !important;
}

.customInput {
  color: var(--home-color) !important;
}

.MuiInput-underline.Mui-disabled:before {
  border-bottom: solid 1px var(--grey-ind-color);
  border-top: solid 1px var(--grey-ind-color);
}

.customInput.Mui-disabled {
  color: var(--grey-ind-color) !important;
}

.customInput.Mui-focused {
  border: solid 1px var(--grey-ind-color) !important;
}

.customInput:hover {
  border: solid 1px var(--grey-ind-color) !important;
}

.customInput {
  border: solid 1px var(--grey-ind-color) !important;
}

.disableInputIcon {
  color: var(--grey-ind-color) !important;
}

.enableInputIcon {
  color: var(--blue-color) !important;
}

.buttonLinkSimple {
  text-transform: none !important;
  font-size: 0.88em !important;
  font-weight: 400 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  text-decoration: underline !important;
  color: var(--home-color) !important;
}

.buttonLinkSimple:hover {
  background-color: var(--white-color) !important;
}

.buttonLinkSimple:active {
  background-color: var(--white-color) !important;
}

.alertIcon {
  color: var(--error-color) !important;
  font-size: 2.22em !important;
}

.infoIcon {
  color: var(--blue-color) !important;
  font-size: 2.22em !important;
}

.iconsAlert {
  display: initial !important;
  text-align: center !important;
}

.btnAlerts {
  height: 40px !important;
  width: 229px !important;
  margin-top: 0px;
  padding-top: 2px !important;
  color: var(--white-color) !important;
  font-size: 0.72em !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.5px;
  text-align: center;
}

.btnAlertOk {
  background-color: var(--blue-color) !important;
}

.btnAlertNo {
  background-color: var(--error-color) !important;
}

.formData {
  width: 470px !important;
}

.widthSimple {
  width: 220px !important;
}

.simpleGrid {
  padding-left: 190px !important;
}

.gridnoPadding {
  padding-left: 190px !important;
}

.blueLarge {
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--blue-color);
}

.tableNiveles {
  opacity: 0.9;
  font-size: 0.77em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: var(--home-color);
}

.boderTable {
  border-right: solid 1px var(--border-color) !important;
}

.table thead th {
  border-bottom: 1px solid var(--border-color) !important;
}

.table th {
  border-top: 0px solid var(--border-color) !important;
}

.table td {
  border-top: 0px solid var(--border-color) !important;
  border-bottom: 1px solid var(--border-color) !important;
}

.rangosSelect {
  width: 120px !important;
  font-size: 0.77em !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.14 !important;
  letter-spacing: normal !important;
}

.MuiPaper-root.MuiPopover-paper {
  max-height: 200px;
  border-radius: 6px;
}

.fechaSelect .MuiFormControl-marginNormal {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.fechaSelect .MuiIconButton-root {
  color: var(--blue-color) !important;
}

.fechaSelect .MuiIconButton-root:hover {
  background-color: var(--white-color) !important;
}

.MuiPickersDay-daySelected {
  color: #fff;
  font-weight: 500;
  background-color: var(--blue-color) !important;
}

.menuSeparatorFull {
  height: 1px;
  background-color: var(--white_b-color);
}

.listPracticas {
  background-color: var(--white-color);
  min-height: 160px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 12px 34px -20px var(--white_b-color);
  border: solid 1px var(--grey-color);
  margin-bottom: 25px;
}

.titlePracticas {
  background-color: var(--footer-color);
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--white-color);
  min-height: 55px;

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  text-transform: uppercase;

  padding-left: 40px;
  padding-top: 16px;
}

.titlePracticas2 {
  background-color: var(--footer-color);
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--white-color);
  min-height: 55px;

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  text-transform: uppercase;

  padding-left: 40px;
  padding-top: 16px;
}

button.MuiPaginationItem-root.svg {
  color: var(--blue-color) !important;
}

.MuiPaginationItem-rounded {
  border-radius: 0px;
}

.MuiPaginationItem-outlined {
  border: 1px solid var(--blue-color) !important;
}

.MuiPaginationItem-page {
  border: solid 1px var(--blue-color) !important;
  font-size: 0.77em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.29;
  letter-spacing: 1.5px;
  text-align: center;
  color: var(--blue-color) !important;
}

.itempagination.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: var(--blue-color) !important;
  border-color: var(--blue-color) !important;
}

.MuiPaginationItem-page.Mui-selected {
  color: #fff !important;
  background-color: var(--blue-color) !important;
  border-color: var(--blue-color) !important;
}

.MuiPagination-root {
  margin: 0 auto !important;
  max-width: 380px !important;
}

.columnFilter {
  float: left;
  width: 50%;
}

.columnFilterW {
  float: left;
  width: 70%;
}

.columnFilterR {
  float: left;
  width: 30%;
}

.centraTexto {
  text-align: center;
}

/* Clear floats after the columns */
.rowFilter:after {
  content: "";
  display: table;
  clear: both;
}

.btnFilter {
  width: 160px !important;
  padding-top: 10px !important;
}

.rosco {
  text-align: center;
  width: 544px;
  height: 544px;
  /*box-shadow: 0 2px 4px 0 rgba(217, 217, 217, 0.67);*/
  margin: auto;
}

.roscoContainer {
  position: relative;
  width: 24em;
  height: 24em;
  padding: 2.8em;
  border: dashed 1px;
  border-radius: 50%;
  margin: 1.75em auto 0;
}

.roscoContainer a {
  display: block;
  position: absolute;
  top: 0%;
  left: 50%;
  margin: -2em;
}

.rosco1 {
  transform: rotate(65deg) translate(17.2em) rotate(-70deg);
}

.rosco2 {
  transform: rotate(45deg) translate(6.5em) rotate(-48deg);
}

.rosco3 {
  transform: rotate(65deg) translate(17.2em) rotate(-70deg);
}

.rosco4 {
  transform: rotate(74.8deg) translate(25.4em) rotate(-82.5deg);
}

.rosco5 {
  transform: rotate(65deg) translate(17.2em) rotate(-70deg);
}

.clsone-1 {
  fill: #df6868;
}

.clsone-2 {
  fill: #60b471;
}

.clsone-3 {
  fill: #986ca8;
}

.clsone-4 {
  fill: #3e7bbd;
}

.clsone-5 {
  fill: #88c493;
}

.clsone-6 {
  fill: #d27e40;
}

.clsone-7 {
  fill: #75bb80;
}

.clsone-8 {
  fill: #e38077;
}

.clsone-9 {
  fill: #4587c5;
}

.clsone-10 {
  fill: #db4b59;
}

.clsone-11 {
  fill: #f6f8f8;
}

.clsone-12 {
  fill: #c65c5f;
}

.clsone-12,
.clsone-13,
.clsone-14,
.clsone-15,
.clsone-16,
.clsone-17,
.clsone-18,
.clsone-19,
.clsone-20,
.clsone-21 {
  opacity: 0.25;
}

.clsone-12,
.clsone-13,
.clsone-14,
.clsone-15,
.clsone-16,
.clsone-17,
.clsone-18,
.clsone-19,
.clsone-20,
.clsone-21,
.clsone-22,
.clsone-23,
.clsone-24 {
  isolation: isolate;
}

.clsone-13 {
  fill: #54a464;
}

.clsone-14 {
  fill: #896099;
}

.clsone-15 {
  fill: #3b75b4;
}

.clsone-16 {
  fill: #7ab186;
}

.clsone-17 {
  fill: #c5763c;
}

.clsone-18 {
  fill: #66a572;
}

.clsone-19 {
  fill: #c26f69;
}

.clsone-20 {
  fill: #407eb9;
}

.clsone-21 {
  fill: #ca4655;
}

.clsone-23 {
  font-size: 43.74px;
  fill: #fff;
  font-family: Arial;
  font-weight: 800;
}

.clsone-24 {
  font-size: 15.31px;
  fill: #2d2f30;
  font-family: Arial;
  font-weight: 500;
}

.clsone-25 {
  letter-spacing: -0.02em;
}

.clstwo-1 {
  fill: #df6868;
}

.clstwo-2 {
  fill: #60b471;
}

.clstwo-3 {
  fill: #986ca8;
}

.clstwo-4 {
  fill: #3e7bbd;
}

.clstwo-5 {
  fill: #88c493;
}

.clstwo-6 {
  fill: #d27e40;
}

.clstwo-7 {
  fill: #75bb80;
}

.clstwo-8 {
  fill: #e38077;
}

.clstwo-9 {
  fill: #4587c5;
}

.clstwo-10 {
  fill: #db4b59;
}

.clstwo-11 {
  fill: #f6f8f8;
}

.clstwo-12 {
  fill: #c65c5f;
}

.clstwo-12,
.clstwo-13,
.clstwo-14,
.clstwo-15,
.clstwo-16,
.clstwo-17,
.clstwo-18,
.clstwo-19,
.clstwo-20,
.clstwo-21 {
  opacity: 0.25;
}

.clstwo-12,
.clstwo-13,
.clstwo-14,
.clstwo-15,
.clstwo-16,
.clstwo-17,
.clstwo-18,
.clstwo-19,
.clstwo-20,
.clstwo-21,
.clstwo-22,
.clstwo-23,
.clstwo-24 {
  isolation: isolate;
}

.clstwo-13 {
  fill: #54a464;
}

.clstwo-14 {
  fill: #896099;
}

.clstwo-15 {
  fill: #3b75b4;
}

.clstwo-16 {
  fill: #7ab186;
}

.clstwo-17 {
  fill: #c5763c;
}

.clstwo-18 {
  fill: #66a572;
}

.clstwo-19 {
  fill: #c26f69;
}

.clstwo-20 {
  fill: #407eb9;
}

.clstwo-21 {
  fill: #ca4655;
}

.clstwo-23 {
  font-size: 43.74px;
  fill: #fff;
  font-family: Arial;
  font-weight: 800;
}

.clstwo-24 {
  font-size: 15.31px;
  fill: #2d2f30;
  font-family: Arial;
  font-weight: 500;
}

.clstwo-25 {
  letter-spacing: -0.02em;
}

.clsthree-1 {
  fill: #df6868;
}

.clsthree-2 {
  fill: #60b471;
}

.clsthree-3 {
  fill: #986ca8;
}

.clsthree-4 {
  fill: #3e7bbd;
}

.clsthree-5 {
  fill: #88c493;
}

.clsthree-6 {
  fill: #d27e40;
}

.clsthree-7 {
  fill: #75bb80;
}

.clsthree-8 {
  fill: #e38077;
}

.clsthree-9 {
  fill: #4587c5;
}

.clsthree-10 {
  fill: #db4b59;
}

.clsthree-11 {
  fill: #f6f8f8;
}

.clsthree-12 {
  fill: #c65c5f;
}

.clsthree-12,
.clsthree-13,
.clsthree-14,
.clsthree-15,
.clsthree-16,
.clsthree-17,
.clsthree-18,
.clsthree-19,
.clsthree-20,
.clsthree-21 {
  opacity: 0.25;
}

.clsthree-12,
.clsthree-13,
.clsthree-14,
.clsthree-15,
.clsthree-16,
.clsthree-17,
.clsthree-18,
.clsthree-19,
.clsthree-20,
.clsthree-21,
.clsthree-22,
.clsthree-23,
.clsthree-24 {
  isolation: isolate;
}

.clsthree-13 {
  fill: #54a464;
}

.clsthree-14 {
  fill: #896099;
}

.clsthree-15 {
  fill: #3b75b4;
}

.clsthree-16 {
  fill: #7ab186;
}

.clsthree-17 {
  fill: #c5763c;
}

.clsthree-18 {
  fill: #66a572;
}

.clsthree-19 {
  fill: #c26f69;
}

.clsthree-20 {
  fill: #407eb9;
}

.clsthree-21 {
  fill: #ca4655;
}

.clsthree-23 {
  font-size: 43.74px;
  fill: #fff;
  font-family: Arial;
  font-weight: 800;
}

.clsthree-24 {
  font-size: 15.31px;
  fill: #2d2f30;
  font-family: Arial;
  font-weight: 500;
}

.clsthree-25 {
  letter-spacing: -0.02em;
}

.clsfour-1 {
  fill: #df6868;
}

.clsfour-2 {
  fill: #60b471;
}

.clsfour-3 {
  fill: #986ca8;
}

.clsfour-4 {
  fill: #3e7bbd;
}

.clsfour-5 {
  fill: #88c493;
}

.clsfour-6 {
  fill: #d27e40;
}

.clsfour-7 {
  fill: #75bb80;
}

.clsfour-8 {
  fill: #e38077;
}

.clsfour-9 {
  fill: #4587c5;
}

.clsfour-10 {
  fill: #db4b59;
}

.clsfour-11 {
  fill: #f6f8f8;
}

.clsfour-12 {
  fill: #c65c5f;
}

.clsfour-12,
.clsfour-13,
.clsfour-14,
.clsfour-15,
.clsfour-16,
.clsfour-17,
.clsfour-18,
.clsfour-19,
.clsfour-20,
.clsfour-21 {
  opacity: 0.25;
}

.clsfour-12,
.clsfour-13,
.clsfour-14,
.clsfour-15,
.clsfour-16,
.clsfour-17,
.clsfour-18,
.clsfour-19,
.clsfour-20,
.clsfour-21,
.clsfour-22,
.clsfour-23,
.clsfour-24 {
  isolation: isolate;
}

.clsfour-13 {
  fill: #54a464;
}

.clsfour-14 {
  fill: #896099;
}

.clsfour-15 {
  fill: #3b75b4;
}

.clsfour-16 {
  fill: #7ab186;
}

.clsfour-17 {
  fill: #c5763c;
}

.clsfour-18 {
  fill: #66a572;
}

.clsfour-19 {
  fill: #c26f69;
}

.clsfour-20 {
  fill: #407eb9;
}

.clsfour-21 {
  fill: #ca4655;
}

.clsfour-23 {
  font-size: 43.74px;
  fill: #fff;
  font-family: Arial;
  font-weight: 800;
}

.clsfour-24 {
  font-size: 15.31px;
  fill: #2d2f30;
  font-family: Arial;
  font-weight: 500;
}

.clsfour-25 {
  letter-spacing: -0.02em;
}

.clsfive-1 {
  fill: #df6868;
}

.clsfive-2 {
  fill: #60b471;
}

.clsfive-3 {
  fill: #986ca8;
}

.clsfive-4 {
  fill: #3e7bbd;
}

.clsfive-5 {
  fill: #88c493;
}

.clsfive-6 {
  fill: #d27e40;
}

.clsfive-7 {
  fill: #75bb80;
}

.clsfive-8 {
  fill: #e38077;
}

.clsfive-9 {
  fill: #4587c5;
}

.clsfive-10 {
  fill: #db4b59;
}

.clsfive-11 {
  fill: #f6f8f8;
}

.clsfive-12 {
  fill: #c65c5f;
}

.clsfive-12,
.clsfive-13,
.clsfive-14,
.clsfive-15,
.clsfive-16,
.clsfive-17,
.clsfive-18,
.clsfive-19,
.clsfive-20,
.clsfive-21 {
  opacity: 0.25;
}

.clsfive-12,
.clsfive-13,
.clsfive-14,
.clsfive-15,
.clsfive-16,
.clsfive-17,
.clsfive-18,
.clsfive-19,
.clsfive-20,
.clsfive-21,
.clsfive-22,
.clsfive-23,
.clsfive-24 {
  isolation: isolate;
}

.clsfive-13 {
  fill: #54a464;
}

.clsfive-14 {
  fill: #896099;
}

.clsfive-15 {
  fill: #3b75b4;
}

.clsfive-16 {
  fill: #7ab186;
}

.clsfive-17 {
  fill: #c5763c;
}

.clsfive-18 {
  fill: #66a572;
}

.clsfive-19 {
  fill: #c26f69;
}

.clsfive-20 {
  fill: #407eb9;
}

.clsfive-21 {
  fill: #ca4655;
}

.clsfive-23 {
  font-size: 43.74px;
  fill: #fff;
  font-family: Arial;
  font-weight: 800;
}

.clsfive-24 {
  font-size: 15.31px;
  fill: #2d2f30;
  font-family: Arial;
  font-weight: 500;
}

.clsfive-25 {
  letter-spacing: -0.02em;
}

.clssix-1 {
  fill: #df6868;
}

.clssix-2 {
  fill: #60b471;
}

.clssix-3 {
  fill: #986ca8;
}

.clssix-4 {
  fill: #3e7bbd;
}

.clssix-5 {
  fill: #88c493;
}

.clssix-6 {
  fill: #d27e40;
}

.clssix-7 {
  fill: #75bb80;
}

.clssix-8 {
  fill: #e38077;
}

.clssix-9 {
  fill: #4587c5;
}

.clssix-10 {
  fill: #db4b59;
}

.clssix-11 {
  fill: #f6f8f8;
}

.clssix-12 {
  fill: #c65c5f;
}

.clssix-12,
.clssix-13,
.clssix-14,
.clssix-15,
.clssix-16,
.clssix-17,
.clssix-18,
.clssix-19,
.clssix-20,
.clssix-21 {
  opacity: 0.25;
}

.clssix-12,
.clssix-13,
.clssix-14,
.clssix-15,
.clssix-16,
.clssix-17,
.clssix-18,
.clssix-19,
.clssix-20,
.clssix-21,
.clssix-22,
.clssix-23,
.clssix-24 {
  isolation: isolate;
}

.clssix-13 {
  fill: #54a464;
}

.clssix-14 {
  fill: #896099;
}

.clssix-15 {
  fill: #3b75b4;
}

.clssix-16 {
  fill: #7ab186;
}

.clssix-17 {
  fill: #c5763c;
}

.clssix-18 {
  fill: #66a572;
}

.clssix-19 {
  fill: #c26f69;
}

.clssix-20 {
  fill: #407eb9;
}

.clssix-21 {
  fill: #ca4655;
}

.clssix-23 {
  font-size: 43.74px;
  fill: #fff;
  font-family: Arial;
  font-weight: 800;
}

.clssix-24 {
  font-size: 15.31px;
  fill: #2d2f30;
  font-family: Arial;
  font-weight: 500;
}

.clssix-25 {
  letter-spacing: -0.02em;
}

.clsseven-1 {
  fill: #df6868;
}

.clsseven-2 {
  fill: #60b471;
}

.clsseven-3 {
  fill: #986ca8;
}

.clsseven-4 {
  fill: #3e7bbd;
}

.clsseven-5 {
  fill: #88c493;
}

.clsseven-6 {
  fill: #d27e40;
}

.clsseven-7 {
  fill: #75bb80;
}

.clsseven-8 {
  fill: #e38077;
}

.clsseven-9 {
  fill: #4587c5;
}

.clsseven-10 {
  fill: #db4b59;
}

.clsseven-11 {
  fill: #f6f8f8;
}

.clsseven-12 {
  fill: #c65c5f;
}

.clsseven-12,
.clsseven-13,
.clsseven-14,
.clsseven-15,
.clsseven-16,
.clsseven-17,
.clsseven-18,
.clsseven-19,
.clsseven-20,
.clsseven-21 {
  opacity: 0.25;
}

.clsseven-12,
.clsseven-13,
.clsseven-14,
.clsseven-15,
.clsseven-16,
.clsseven-17,
.clsseven-18,
.clsseven-19,
.clsseven-20,
.clsseven-21,
.clsseven-22,
.clsseven-23,
.clsseven-24 {
  isolation: isolate;
}

.clsseven-13 {
  fill: #54a464;
}

.clsseven-14 {
  fill: #896099;
}

.clsseven-15 {
  fill: #3b75b4;
}

.clsseven-16 {
  fill: #7ab186;
}

.clsseven-17 {
  fill: #c5763c;
}

.clsseven-18 {
  fill: #66a572;
}

.clsseven-19 {
  fill: #c26f69;
}

.clsseven-20 {
  fill: #407eb9;
}

.clsseven-21 {
  fill: #ca4655;
}

.clsseven-23 {
  font-size: 43.74px;
  fill: #fff;
  font-family: Arial;
  font-weight: 800;
}

.clsseven-24 {
  font-size: 15.31px;
  fill: #2d2f30;
  font-family: Arial;
  font-weight: 500;
}

.clsseven-25 {
  letter-spacing: -0.02em;
}

.clsseight-1 {
  fill: #df6868;
}

.clsseight-2 {
  fill: #60b471;
}

.clsseight-3 {
  fill: #986ca8;
}

.clsseight-4 {
  fill: #3e7bbd;
}

.clsseight-5 {
  fill: #88c493;
}

.clsseight-6 {
  fill: #d27e40;
}

.clsseight-7 {
  fill: #75bb80;
}

.clsseight-8 {
  fill: #e38077;
}

.clsseight-9 {
  fill: #4587c5;
}

.clsseight-10 {
  fill: #db4b59;
}

.clsseight-11 {
  fill: #f6f8f8;
}

.clsseight-12 {
  fill: #c65c5f;
}

.clsseight-12,
.clsseight-13,
.clsseight-14,
.clsseight-15,
.clsseight-16,
.clsseight-17,
.clsseight-18,
.clsseight-19,
.clsseight-20,
.clsseight-21 {
  opacity: 0.25;
}

.clsseight-12,
.clsseight-13,
.clsseight-14,
.clsseight-15,
.clsseight-16,
.clsseight-17,
.clsseight-18,
.clsseight-19,
.clsseight-20,
.clsseight-21,
.clsseight-22,
.clsseight-23,
.clsseight-24 {
  isolation: isolate;
}

.clsseight-13 {
  fill: #54a464;
}

.clsseight-14 {
  fill: #896099;
}

.clsseight-15 {
  fill: #3b75b4;
}

.clsseight-16 {
  fill: #7ab186;
}

.clsseight-17 {
  fill: #c5763c;
}

.clsseight-18 {
  fill: #66a572;
}

.clsseight-19 {
  fill: #c26f69;
}

.clsseight-20 {
  fill: #407eb9;
}

.clsseight-21 {
  fill: #ca4655;
}

.clsseight-23 {
  font-size: 43.74px;
  fill: #fff;
  font-family: Arial;
  font-weight: 800;
}

.clsseight-24 {
  font-size: 15.31px;
  fill: #2d2f30;
  font-family: Arial;
  font-weight: 500;
}

.clsseight-25 {
  letter-spacing: -0.02em;
}

.clsnine-1 {
  fill: #df6868;
}

.clsnine-2 {
  fill: #60b471;
}

.clsnine-3 {
  fill: #986ca8;
}

.clsnine-4 {
  fill: #3e7bbd;
}

.clsnine-5 {
  fill: #88c493;
}

.clsnine-6 {
  fill: #d27e40;
}

.clsnine-7 {
  fill: #75bb80;
}

.clsnine-8 {
  fill: #e38077;
}

.clsnine-9 {
  fill: #4587c5;
}

.clsnine-10 {
  fill: #db4b59;
}

.clsnine-11 {
  fill: #f6f8f8;
}

.clsnine-12 {
  fill: #c65c5f;
}

.clsnine-12,
.clsnine-13,
.clsnine-14,
.clsnine-15,
.clsnine-16,
.clsnine-17,
.clsnine-18,
.clsnine-19,
.clsnine-20,
.clsnine-21 {
  opacity: 0.25;
}

.clsnine-12,
.clsnine-13,
.clsnine-14,
.clsnine-15,
.clsnine-16,
.clsnine-17,
.clsnine-18,
.clsnine-19,
.clsnine-20,
.clsnine-21,
.clsnine-22,
.clsnine-23,
.clsnine-24 {
  isolation: isolate;
}

.clsnine-13 {
  fill: #54a464;
}

.clsnine-14 {
  fill: #896099;
}

.clsnine-15 {
  fill: #3b75b4;
}

.clsnine-16 {
  fill: #7ab186;
}

.clsnine-17 {
  fill: #c5763c;
}

.clsnine-18 {
  fill: #66a572;
}

.clsnine-19 {
  fill: #c26f69;
}

.clsnine-20 {
  fill: #407eb9;
}

.clsnine-21 {
  fill: #ca4655;
}

.clsnine-23 {
  font-size: 43.74px;
  fill: #fff;
  font-family: Arial;
  font-weight: 800;
}

.clsnine-24 {
  font-size: 15.31px;
  fill: #2d2f30;
  font-family: Arial;
  font-weight: 500;
}

.clsnine-25 {
  letter-spacing: -0.02em;
}

.clsten-1 {
  fill: #df6868;
}

.clsten-2 {
  fill: #60b471;
}

.clsten-3 {
  fill: #986ca8;
}

.clsten-4 {
  fill: #3e7bbd;
}

.clsten-5 {
  fill: #88c493;
}

.clsten-6 {
  fill: #d27e40;
}

.clsten-7 {
  fill: #75bb80;
}

.clsten-8 {
  fill: #e38077;
}

.clsten-9 {
  fill: #4587c5;
}

.clsten-10 {
  fill: #db4b59;
}

.clsten-11 {
  fill: #f6f8f8;
}

.clsten-12 {
  fill: #c65c5f;
}

.clsten-12,
.clsten-13,
.clsten-14,
.clsten-15,
.clsten-16,
.clsten-17,
.clsten-18,
.clsten-19,
.clsten-20,
.clsten-21 {
  opacity: 0.25;
}

.clsten-12,
.clsten-13,
.clsten-14,
.clsten-15,
.clsten-16,
.clsten-17,
.clsten-18,
.clsten-19,
.clsten-20,
.clsten-21,
.clsten-22,
.clsten-23,
.clsten-24 {
  isolation: isolate;
}

.clsten-13 {
  fill: #54a464;
}

.clsten-14 {
  fill: #896099;
}

.clsten-15 {
  fill: #3b75b4;
}

.clsten-16 {
  fill: #7ab186;
}

.clsten-17 {
  fill: #c5763c;
}

.clsten-18 {
  fill: #66a572;
}

.clsten-19 {
  fill: #c26f69;
}

.clsten-20 {
  fill: #407eb9;
}

.clsten-21 {
  fill: #ca4655;
}

.clsten-23 {
  font-size: 43.74px;
  fill: #fff;
  font-family: Arial;
  font-weight: 800;
}

.clsten-24 {
  font-size: 15.31px;
  fill: #2d2f30;
  font-family: Arial;
  font-weight: 500;
}

.clsten-25 {
  letter-spacing: -0.02em;
}

.clsten-23b {
  font-weight: 800;
  font-size: 43.74000168px;
  font-family: Arial;
  isolation: isolate;
  fill: #ffffff;
}

.clsten-24b {
  font-weight: 500;
  font-size: 15.31000042px;
  font-family: Arial;
  isolation: isolate;
  fill: #2d2f30;
}

.clsten-25b {
  letter-spacing: -0.02em;
}

.clsten-24c {
  font-weight: 500;
  font-size: 15.31000042px;
  font-family: Arial;
  isolation: isolate;
  fill: #2d2f30;
}

.clsonec-1 {
  fill: #c65c5f;
}

.clsonec-2 {
  fill: #54a564;
}

.clsonec-3 {
  fill: #895f99;
}

.clsonec-4 {
  fill: #88c493;
}

.clsonec-5 {
  fill: #c5763c;
}

.clsonec-6 {
  fill: #54a664;
}

.clsonec-7 {
  fill: #c26f69;
}

.clsonec-8 {
  fill: #367bbc;
}

.clsonec-9 {
  fill: #ca4655;
}

.clsonec-10 {
  fill: #df6868;
}

.clsonec-11 {
  fill: #5eb26b;
}

.clsonec-12 {
  fill: #986ba8;
}

.clsonec-13 {
  fill: #88c593;
}

.clsonec-14 {
  fill: #d27f40;
}

.clsonec-15 {
  fill: #5eb16b;
}

.clsonec-16 {
  fill: #e38077;
}

.clsonec-17 {
  fill: #4586c5;
}

.clsonec-18 {
  fill: #db4b59;
}

.clsonec-19 {
  fill: #54a464;
}

.clsonec-20 {
  fill: #896099;
}

.clsonec-21 {
  fill: #7ab186;
}

.clsonec-22 {
  fill: #367abc;
}

.clsonec-23 {
  fill: #75bb80;
}

.clsonec-24 {
  fill: #d27e40;
}

.clsonec-25 {
  fill: #986ca8;
}

.clsonec-26 {
  fill: #4587c5;
}

.clsonec-27 {
  fill: #60b471;
}

.clsonec-28,
.clsonec-29,
.clsonec-30,
.clsonec-34 {
  isolation: isolate;
}

.clsonec-29 {
  font-size: 50.25px;
}

.clsonec-29,
.clsonec-30,
.clsonec-34 {
  fill: #fff;
}

.clsonec-29,
.clsonec-34 {
  font-family: Arial;
  font-weight: 800;
}

.clsonec-30 {
  font-size: 18.38px;
  font-family: Arial;
  font-weight: 500;
}

.clsonec-31 {
  letter-spacing: -0.02em;
}

.clsonec-32 {
  letter-spacing: 0em;
}

.clsonec-33 {
  letter-spacing: -0.02em;
}

.clsonec-34 {
  font-size: 50.27px;
}

.clsonec-t {
  font-weight: 500;
  font-size: 18.37999916px;
  font-family: Arial;
  isolation: isolate;
  fill: #ffffff;
}

.clsonec-t2 {
  font-weight: 800;
  font-size: 50.27000046px;
  font-family: Arial;
  isolation: isolate;
  fill: #ffffff;
}

.txtRoscoComplete {
  fill: #ffffff !important;
  color: #ffffff !important;
  font-weight: 500 !important;
}

.imgListadoPracticas {
  width: 220px !important;
}

.txtResultadosTitle {
  font-size: 0.88em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;
  color: var(--home-color);
}

.txtInfoExplica {
  font-size: 0.78em;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.8px !important;
  text-align: justify;
  margin-bottom: 15px;
  color: var(--home-color);
  margin-top: 15px;
}

.toUpperCase {
  text-transform: uppercase;
}

/* menu resultado */
.tabsRes {
  background-color: #ffffff !important;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
}

.MuiTab-root {
  font-family: MuseoSans !important;
  color: #85888c !important;
  min-width: 120px !important;
}

.MuiTabs-indicator {
  background-color: var(--blue-color) !important;
}

.tabPadding .MuiBox-root {
  padding-left: 8px !important;
}

.tabSelect {
  width: 180px !important;
  font-size: 0.77em !important;
  font-weight: 300 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  color: #565858 !important;
  background-color: var(--grey-color) !important;
  padding-left: 8px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.tabSelect:before {
  border-bottom: 0px solid !important;
}

.tabTextTitle {
  font-size: 0.66em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.03px;
  color: #383b41;
  text-transform: uppercase;
}

.subTitleRes {
  font-size: 0.77em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--home-color) !important;
}

.borderTitle {
  border-bottom: solid 2px var(--white_b-color);
  margin-bottom: 17px;
  padding-bottom: 5px;
}

.listaClaves {
  font-size: 0.66em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: var(--home-color) !important;
  margin-bottom: 10px !important;
}

.btnDownloadAzul {
  height: 40px !important;
  width: 150px !important;
  padding-top: 7px !important;
  text-transform: uppercase !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.07) !important;
  background-color: var(--blue-color) !important;
  font-size: 0.77em !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.04px;
  color: var(--white-color) !important;
  border: solid 2px var(--blue-color) !important;
}

.btnDownloadAzul:hover {
  background-color: var(--blue-hover) !important;

  color: var(--white-color) !important;
}

.btnDownload {
  height: 40px !important;
  width: 150px !important;
  padding-top: 7px !important;
  text-transform: uppercase !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.07) !important;
  background-color: var(--white-color) !important;
  font-size: 0.77em !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.04px;
  color: var(--blue-color) !important;
  border: solid 2px var(--blue-color) !important;
}

.btnDownload:hover {
  background-color: var(--blue-color) !important;

  color: var(--white-color) !important;
}

.posDownload {
  float: right;
  margin-right: 20px;
  margin-top: 10px;
}

.txtMedia {
  font-size: 0.88em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.03px;
  text-align: right;
  color: var(--home-color);
  float: right;
  margin-right: 30px;
}

.modalTablet .modal-content {
  margin-top: 200px;
  margin-left: 30px;
  width: 400px;
  height: 400px;
  border-radius: 4px;
  box-shadow: 0 2px 16px 0 #c1c7d0;
  border: solid 1px var(--white_b-color);
  background-color: var(--white-color);
  text-align: center;
}

.bgGreyRes {
  background-color: var(--grey-color) !important;
  border-left: solid 2px var(--white_b-color) !important;
  padding-left: 15px !important;
}

.imgMapaRes {
  width: 600px;
  height: 500px;
}

.mediaEsp {
  width: 28;
  height: 28;
  border-radius: 50%;
  border: 1px dashed var(--home-color);
}

.mediaCom {
  width: 28;
  height: 28;
  border-radius: 50%;
  background-color: #a6c2e0;
  font-size: 0.77em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;

  letter-spacing: normal;
  text-align: right;
  color: var(--home-color);
}

.hRes {
  height: 40px;
}

.mediaComunidad {
  font-size: 0.72em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: normal;
  color: var(--home-color);
}

.btnDownloadGraf {
  margin-right: 20px;
  width: 100% !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  font-size: 0.66em !important;
  z-index: 10;
}

.btnDownloadInforme {
  margin-right: 20px;
  width: 175px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px !important;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.07) !important;
  background-color: var(--blue-color) !important;
  font-size: 0.66em !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;

  letter-spacing: 0.5px !important;
  text-align: left !important;
  color: var(--white-color) !important;
}

.btnDownloadInforme:hover {
  background-color: var(--blue-hover) !important;
}

.btnDownloadInformeInv {
  width: 235px !important;
}

.btnNavBar {
  width: 174px !important;
  height: 50px !important;
  padding-top: 15px;
}

.btnNavBarTool {
  width: 232px !important;
}

.underline {
  border-bottom: solid 2px var(--white-color) !important;
}

.ant-menu-item-selected {
  border-bottom: solid 2px var(--white-color) !important;
}

.cPaddingTop {
  padding-top: 12px;
}

.customMt {
  margin-top: 10px;
}

.customMt2 {
  margin-top: 18px;
}

.tabSelect {
  background-color: var(--white-color) !important;
}

.css-tlfecz-indicatorContainer {
  color: var(--blue-color) !important;
}

.selectFilters {
  max-width: 215px !important;
  width: 190px !important;
  font-size: 0.8em !important;
  height: 30px !important;
  min-width: 185px !important;
  max-height: 30px !important;
}

.multi-select {
  max-width: 235px !important;
  width: 235px !important;
  font-size: 0.8em !important;
  height: 30px !important;
  min-width: 235px !important;
  max-height: 30px !important;
  z-index: 9000 !important;
}

.selectFilters__value-container {
  height: 30px !important;
  padding: 0 !important;
}

.selectFilters .ValueContainer {
  height: 30px !important;
  padding: 0 !important;
}

.lateralFiltros {
  max-height: 450px;
  overflow: auto;
}

.historicof {
  max-width: 90px !important;
  width: 90px !important;
}

.mInforme {
  width: 210px !important;
}

.w3-border {
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #dfe1e5 !important;
  height: 2px;
}

.w3-grey {
  background-color: #a6c2e0 !important;
  height: 2px;
}

.w3-media {
  background-color: #565858 !important;
}

.viewToolkit {
  padding: 40px;
  text-align: justify !important;
}

.listToolkit {
  background-color: var(--white-color);
  min-height: 10px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 12px 34px -20px var(--white_b-color);
  border: solid 1px var(--grey-color);
  margin-bottom: 25px;
}

.paddingRecursos {
  padding-right: 90px;
  padding-left: 90px;
}

.cuerpoRecurso {
  box-shadow: 0 12px 34px -20px #dfe1e5;
  border: solid 1px #ebecf0;
  background-color: #ffffff;
  color: #808184;
  font-size: 0.88em;
  padding: 15px;
  line-height: 20px;
}

.bottomRecursos {
  background-color: #fafbfc;
  height: 100px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 16;
}

.paddingBotones {}

.marginLeftButton {
  margin-left: 10px !important;
}

.botonesAccion {
  position: fixed;
  z-index: 300;
}

.underlinetool {
  border-bottom: solid 2px #2d2f30;
}

.blackMedia {
  width: 30px;
  height: 10px;
  background-color: black;
  float: left;
  margin-top: 1px;
  margin-right: 5px;
}

.legendMedia {
  font-size: 11px;
}

.mediaRainwow {
  float: left;
  margin-top: 1px;
  margin-right: 5px;
  width: 30px;
  height: 10px;
  background: linear-gradient(to right,
      #3e7abd,
      #3e7abd 20%,
      #60b471 20%,
      #60b471 40%,
      #cca8ce 40%,
      #cca8ce 60%,
      #db4b59 60%,
      #db4b59 80%,
      #eb7f39 80%,
      #eb7f39);
}

.bocadillo-cuadrado {
  float: right;
  margin-right: 50px;
  padding-top: 35px;
  position: relative;
  height: 300px;
  width: 500px;
  background: black;
}

.bocadillo-cuadrado:before {
  content: "";
  display: block;
  position: absolute;
  left: 100%;
  top: 50%;
  margin-top: -15px;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid black;
}

.toolkitPregunta {
  font-size: 1.375em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: white;
  padding: 20px;
}

.titleToolkit {
  font-size: 2em;
  font-weight: bold;
}

.subtoolkit {
  font-size: 1.375em;
}

.descToolkit {
  padding-right: 80px;
}

.infofichad {
  font-size: 17px;
  font-weight: bold;
}

.bocclave1 {
  background: #3e7bbd;
}

.bocclave1:before {
  border-left: 20px solid #3e7bbd;
}

.bocclave2 {
  background: #4587c5;
}

.bocclave2:before {
  border-left: 20px solid #4587c5;
}

.bocclave3 {
  background: #60b471;
}

.bocclave3:before {
  border-left: 20px solid #60b471;
}

.bocclave4 {
  background: #75bb80;
}

.bocclave4:before {
  border-left: 20px solid #75bb80;
}

.bocclave5 {
  background: #88c493;
}

.bocclave5:before {
  border-left: 20px solid #88c493;
}

.bocclave6 {
  background: #986ca8;
}

.bocclave6:before {
  border-left: 20px solid #986ca8;
}

.bocclave7 {
  background: #db4b59;
}

.bocclave7:before {
  border-left: 20px solid #db4b59;
}

.bocclave8 {
  background: #df6868;
}

.bocclave8:before {
  border-left: 20px solid #df6868;
}

.bocclave9 {
  background: #e38077;
}

.bocclave9:before {
  border-left: 20px solid #e38077;
}

.bocclave10 {
  background: #d27e40;
}

.bocclave10:before {
  border-left: 20px solid #d27e40;
}

.borderRegister {
  margin-top: 20px;
  border: solid 2px var(--home-color) !important;
  border-radius: 10px;
  padding: 20px;
}

.infoRegister {
  color: var(--home-color);
  font-size: 17px !important;
  font-weight: 600;
}

.customtooltipclass {
  background-color: rgba(94, 94, 94, 0.89);
  border-radius: 18px;
  font-size: 0.77em !important;
  padding: 12px;
}

.customarrowclass {
  background-color: rgba(94, 94, 94, 0.89);
}

.Select {
  width: 444px !important;
  margin-right: 5px;
  z-index: 0 !important;
}

.Select.is-open {
  z-index: 1 !important;
}

.Select-menu {
  position: fixed !important;
  width: 440px !important;
  margin: 1px;
}

.divLoader {
  z-index: 10000;
  position: absolute;
  background: rgb(0, 0, 0, 0.65);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bubbleTestimonial {
  position: relative;
  background-color: var(--grey-color);
  margin: 30;
  height: 280px;
  width: 450px;
  padding: 10;
  text-align: center;
}

.bubbleTestimonial:before {
  content: "";
  border-top: 20px solid var(--grey-color);
  border-right: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid transparent;
  position: absolute;
  top: 280px !important;
  left: calc(50%);
}

.nombreTestimonio {
  margin-top: 20px;
  padding-top: 20px;
  margin: auto;
  color: var(--corpo-color);
  font-size: 0.77em;
  font-weight: 600;
}

.negrita {
  font-weight: 800 !important;
}

.botonRegistroNuevo {
  cursor: pointer;
  width: 300px;
}

.cargoTestimonio {
  font-size: 0.92em;
  font-weight: 600;
  align-items: center;
  color: black !important;
}

.opinionTestimonio {
  margin-top: 15px;
  font-size: 0.92em;
  font-style: italic;
  position: absolute;
  padding: 10px;
  white-space: break-spaces !important;
  color: black !important;
}

.stars {
  margin-top: 5px;
  padding: 10;
}

.starok {
  color: var(--corpo-color);
}

.starno {
  color: var(--grey-dark);
}

.marginLeftHome {
  margin-left: 80px;
}

.marginRightHome {
  margin-right: 80px;
}

.nopadding {
  padding: 5px !important;
}

.homecontainernobottom {
  padding-bottom: 10px !important;
}

.mPaddingR {
  padding-right: 35px !important;
}

.dashedBottom {
  margin-top: 20px;
  border-bottom: 3px dashed var(--grey-dark);
}

.dashedRight {
  border-right: 3px dashed var(--grey-dark);
  float: right;
  height: 85%;
}

.padd10 {
  padding-right: 15px;
}

.padd10l {
  padding-left: 5px;
}

.marg10 {
  margin-top: 10px !important;
}

.cutW {
  width: 97%;
}

.menosM {
  margin-left: -10px;
}

.marginLeftFotoXcelence {
  margin-left: 200px;
}

.moveBorder {
  margin-right: 20px;
}

.containerCentros {
  display: flex;
  flex-direction: row;
  height: 300px;
}

.center-col {
  flex: 1;
  background: #fff;
  overflow-y: scroll;
}

.itemCentro {
  margin-top: 5px;
  border-bottom: 1px solid var(--grey-dark);
  margin-bottom: 5px;
}

.centroNameList {
  font-size: 1.1em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.5px;
  color: var(--blue-color) !important;
  margin-bottom: 4px;
}

.centroAddressList {
  font-size: 0.9em;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.5px;
  color: var(--grey-dark) !important;
  margin-bottom: 4px;
}

.centroCodigoList {
  margin-top: 6px;
  font-weight: bold;
  font-size: 1.3em;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.5px;
  color: var(--grey-dark) !important;
  margin-bottom: 6px;
}

.derecha {
  text-align: right;
  cursor: pointer;
}

.imageHover {
  opacity: 1;
  display: block;

  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  width: 300px;
  height: auto;
}

.containerImageRegister {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
}

.containerImageRegister:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.ant-menu-horizontal {
  line-height: 46px;
  border: 0;
  border-bottom: 0px solid #f0f0f0 !important;
  box-shadow: none;
}

.ant-menu {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum';
  margin-bottom: 0;
  padding-left: 0;
  color: #FFFFFF !important;
  font-size: 14px;
  line-height: 0;
  text-align: left;
  list-style: none;
  background: var(--corpo-color) !important;
  ;
  outline: none;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected {
  color: #ffffff !important;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after {
  border-bottom: 3px solid #FFFFFF !important;
}

.ant-menu-item-selected {
  border-bottom: solid 0px var(--white-color) !important;
}


.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after {
  border-bottom: 3px solid #FFFFFF !important;
}

.ant-menu-item-selected {
  border-bottom: solid 0px var(--white-color) !important;
}

.menu-item-link {
  font-family: MuseoSans !important;
  font-size: 1.0em !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: 0.5px !important;
  color: var(--white-color) !important;
}









.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected::after {
  border-bottom: 0px solid #1890ff !important;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after {
  border-bottom: 0px solid #1890ff !important;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active:hover {
  border-bottom: 0px solid #ffffff !important;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active:hover {
  border-bottom: 0px solid #ffffff !important;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected:hover {
  border-bottom: 0px solid #ffffff !important;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected:hover {
  border-bottom: 0px solid #ffffff !important;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after {
  border-bottom: 0px solid #ffffff !important;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after {
  border-bottom: 0px solid #ffffff !important;
}


.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active:hover::after {
  border-bottom: 0px solid #ffffff !important;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active:hover::after {
  border-bottom: 0px solid #ffffff !important;
}



.menuPrincipal {
  background-color: var(--corpo-color) !important;
  color: white !important;
}


.ant-menu-sub {
  background-color: var(--corpo-color) !important
}

.ant-menu-submenu {
  font-family: MuseoSans !important;
  font-size: 1em !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: 0.5px !important;
  color: var(--white-color) !important;
}

.ant-menu-submenu-active {
  color: var(--white-color) !important;
  border-bottom: solid 2px var(--white-color) !important;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected {
  border-bottom: solid 2px var(--white-color) !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: var(--corpo-color) !important
}

.ant-menu-submenu-open {
  color: var(--white-color) !important;
}

.ant-menu-submenu-open:hover {
  color: var(--white-color) !important;
}

.ant-menu-submenu-title {
  color: var(--white-color) !important;
}

.ant-menu-submenu-title:hover {
  color: var(--white-color) !important;
}

.ant-menu-submenu-vertical-left {
  color: var(--white-color) !important;
}

.ant-menu-submenu-vertical-left:hover {
  color: var(--white-color) !important;
}

.ant-menu-item {
  font-family: MuseoSans !important;
  font-size: 1.0em !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: 0.5px !important;
  color: var(--white-color) !important;
}

.menu-item-link {
  font-family: MuseoSans !important;
  font-size: 1.0em !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: 0.5px !important;
  color: var(--white-color) !important;
}

.ant-menu-submenu-arrow {
  color: var(--white-color) !important;

}

.ant-menu-submenu-arrow:hover {
  color: var(--white-color) !important;

}

.menu-subitem-r {
  background: url(./white.svg) no-repeat center right;

  padding: 10px 21px !important;
  margin: 0px 0px !important;
  width: 100% !important;
  padding-right: 20px !important;
}

.menu-item-link-sub {
  font-family: MuseoSans !important;
  font-size: 1.0em !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: 0.5px !important;
  color: var(--white-color) !important;

}


.btnColorBlue {
  color: var(--blue-color) !important;
  padding-left: 0px;
}

.menuPrincipal {
  padding-top: 12px !important;
}

.navbarDisplayFlex {
  display: "flex";
  justify-content: "space-between"
}

.navDisplayFlex {
  display: "flex";
  justify-content: "space-between"
}

.linkText {
  text-decoration: "none";
  text-transform: "uppercase";
  color: "white";
}


.menuPrincipal {
  background: var(--corpo-color);
}


.submenu-item-navbar {
  font-family: MuseoSans !important;
  font-size: 0.75em !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: 0.5px !important;
  color: var(--white-color) !important;

}

.submenu-item-navbar:hover {
  border-bottom: solid 2px var(--white-color) !important;

}

.submenu-item-navbar-selected {
  border-bottom: solid 2px var(--white-color) !important;

}

.menu-item-navbar {
  font-family: MuseoSans !important;
  font-size: 1.0em !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: 0.5px !important;
  color: var(--white-color) !important;

}

.menu-item-navbar:hover {
  border-bottom: solid 2px var(--white-color) !important;

}

.menu-item-navbar-selected {
  border-bottom: solid 2px var(--white-color) !important;

}

.menu-item-navbar-inverso {
  background-color: white !important;
  border-radius: 90px !important;
  color: var(--blue-color) !important;
  padding-left: 0px;
}

.navbar-light .navbar-toggler {
  color: rgba(255, 255, 255, .5);
  border-color: var(--corpo-color) !important;
}

.navbar-light .navbarToggleNew {
  color: var(--primary-color);
  border-color: var(--corpo-color);
}

.navbar-light .navbar-toggler-icon {
  background-image: url(burger.png);
}

.navbarToggleNew .navbar-toggler-icon {
  background-image: url(burger.svg);
}


.navbar-light .navbar-nav .nav-link {
  color: white;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: white;

}

.navbar-light .navbar-nav .show>.nav-link {
  color: white;
}


.posMenu {
  color: var(--white-color);
  margin-left: 3px;
  float: right;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
  background-color: var(--corpo-color) !important;
  left: auto;
  right: 0;
  margin-right: -10px;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: white;
  text-decoration: none;
  background-color: var(--corpo-color) !important;
  ;
}

.dropdown-item {
  padding: .5rem 0.4rem;
}

.dropdown-menu {
  min-width: 13.5rem !important;
}

.menuHori {

  margin-right: 15px;
}

.moveMenu .dropdown-menu .show {
  left: -90px !important
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

.imgDownload {
  width: 750px !important;
}

.butonBottomAdjust {
  position: absolute !important;
  left: 160% !important;
}

.alignCenter {
  padding: 10px !important;
  text-align: center !important;
  color: #fff !important
}

.cTextArea {
  background-color: #FFFFFF !important;
}

.comoRelaciona {
  margin-right: 280px !important;
  margin-left: 280px !important;
}

.paddingCeap {
  padding-top: 20px !important;
}

.caruselCeap {
  width: 1000px !important;
  margin: auto !important;
}

.caruselInsideCeap {
  width: 880px !important;
  margin: auto !important;
  display: flex;
}

.iconCeap {
  margin: auto !important;
}

.marginCeap {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.lineCeap {
  font-size: 37px !important;
  line-height: 50px !important;
}

.modal-footer {
  border: 0 !important;
}

.modal-footer-custom label {
  font-size: 16px !important;
}

button[class^=sw-btn],
a[class^=sw-btn] {
  border-radius: 20px;
  border: 0;
  padding: 10px 20px;
  font-size: 0.85rem;
  min-width: 64px;
  box-sizing: border-box;

  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
}

a[class^=sw-btn] {
  padding: 13px 20px;
}

button[class^=sw-btn]:hover,
a[class^=sw-btn]:hover {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  opacity: 0.9;
}

button.sw-btn-primary,
a.sw-btn-primary {
  background-color: #dc0740;
  color: #fff;
}

button.sw-btn-primary:hover,
a.sw-btn-primary:hover {
  color: #fff;
}

button.sw-btn-secondary,
a.sw-btn-secondary {
  background-color: #fff;
  color: #dc0740;
}

button.sw-btn-secondary:hover,
a.sw-btn-secondary:hover {
  color: #dc0740;
}

b,
strong {
  font-weight: 900 !important;
}


button.MuiButton-outlinedPrimary {
  color: var(--blue-color);
  border: 1px solid var(--blue-color);
  padding: 9px 20px;
}

button.MuiButton-outlinedPrimary:hover {
  border: 1px solid var(--blue-hover);
}

button[class^=sw-btn],
a[class^=sw-btn] {
  border-radius: 20px;
  border: 0;
  padding: 10px 20px;
  font-size: 0.85rem;
  min-width: 64px;
  box-sizing: border-box;
  text-align: center;

  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
}

a[class^=sw-btn] {
  padding: 13px 20px;
}

button[class^=sw-btn]:hover,
a[class^=sw-btn]:hover {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  opacity: 0.9;
}

button[class^=sw-btn]:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

button.sw-btn-primary,
a.sw-btn-primary {
  background-color: var(--corpo-color);
  color: #fff;
}

button.sw-btn-primary:hover,
a.sw-btn-primary:hover {
  color: #fff;
}

button.sw-btn-secondary,
a.sw-btn-secondary {
  background-color: #fff;
  color: var(--corpo-color);
}

button.sw-btn-secondary:hover,
a.sw-btn-secondary:hover {
  color: var(--corpo-color);
}

.dropdown-item.active {
  background-color: #c5083a;
}

.btn-square {
  border-radius: 4px !important;
}

.btn-danger {
  background-color: var(--error-color) !important;
}

.bg-silver {
  background-color: var(--silver-color);
}

.bgBlur {
  position: relative;
  z-index: 0;
}

.bgBlur::before {
  content: "";
  position: absolute;
  z-index: -2;
  filter: blur(100px);
  width: 180px;
  height: 180px;
  border-radius: 50%;
  bottom: 0;
  right: 0;
  transform: translate(20%, 20%);
}

.bgBlur::after {
  content: "";
  position: absolute;
  border-radius: 12px;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--white-color);
}

.bgBlurBlue::before {
  background: var(--blue-new-color);
}

.bgBlurGreen::before {
  background: var(--green-light-color);
}

.bgBlurOrange::before {
  background: var(--orange-color);
}

.bgBlurViolet::before {
  background: var(--violet-color);
}

.bgBlurNab {
  z-index: 0;
  position: relative;
}

.bgBlurNab::before {
  z-index: -1;
  content: "";
  position: absolute;
  top: 4%;
  right: 110px;
  width: 6px;
  height: 300px;
  transform: translate(-50%, 0);
  content: '';
  filter: blur(122px);
  rotate: 45deg;
  border-radius: 50%;
  outline: 92px solid #ECB487;
}
.bgBlurNab::after {
  content: "";
  position: absolute;
  width: 240px;
  height: 100px;
  background-color: white;
  border-radius: 100px;
  opacity: 0.3;
  z-index: -1;
  rotate: 45deg;
  right: 10%;
  top: 100px;
}

.bgBlurNavLeft,
.bgBlurNavRight {
  z-index: 0;
  top: 0;
  position: absolute;
}

.bgBlurNavLeft {
  left: 0;
}

.bgBlurNavRight {
  right: 0;
}

.bgBlurNavLeft::before,
.bgBlurNavRight::before {
  z-index: -1;
  content: "";
  position: absolute;
  top: 0;
  right: 70px;
  width: 0;
  height: 279px;
  transform: translate(-50%, 0);
  content: '';
  filter: blur(70px);
  rotate: 45deg;
  border-radius: 50%;
  opacity: 0.4;
}

.bgBlurNavLeft::before {
  rotate: -45deg;
  left: 70px;
  filter: blur(100px);
  opacity: 0.55;
}

.bgBlurNavLeft::after {
  content: "";
  position: absolute;
  width: 250px;
  height: 0.1px;
  background-color: white;
  border-radius: 100px;
  rotate: 45deg;
  left: -160px;
  top: 158px;
  z-index: -1;
  outline: 49px solid white;
  opacity: 0.3;
}

.bgBlurNavRight::after {
  content: "";
  position: absolute;
  width: 240px;
  height: 100px;
  background-color: white;
  border-radius: 100px;
  rotate: 45deg;
  right: 48px;
  top: 77px;
  opacity: 0.3;
  z-index: -1;
}

.bgBlurNavLeft::before {
  outline: 92px solid #E99FA3;
}

.bgBlurNavRight::before {
  outline: 92px solid #81C187;
}

.primary-text {
  color: var(--primary-color);
}

.silver-text {
  color: var(--gris-color);
}

.dark-text {
  color: var(--dark-color);
}

.silver-bg {
  color: var(--silver-color);
}

.subtitleTransform {
  font-size: 24px;
  font-weight: 700;
  line-height: 112.5%;
  margin-bottom: 25px;
}

.textTransform {
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 15px;
}

.textTransform p {
  margin-bottom: 0;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.h2New {
  font-weight: 700;
  font-size: 28px;
  line-height: 1.125;
  color: var(--dark-color);
  margin-bottom: 0.3em;
}

.h3New {
  font-weight: 700;
  font-size: 23px;
  line-height: 1.125;
  color: var(--dark-color);
}

.h5New,
.h5New>div {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.25;
  letter-spacing: 0.42px;
  color: var(--dark-color);
}

.pNew {
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5;
  color: var(--dark-color);
}

.btnNew.btnWrapDirector {
  margin-top: 12px;
  margin-bottom: 30px;
}

.spaceBottom1 {
  margin-bottom: 20px;
}

.font-600 {
  font-weight: 600;
}

#responsive-navbar-nav {
  z-index: 1;
}