@media only screen and (max-width: 595px) {
  .defaultgridNew {
    padding-left: 0;
    padding-right: 0;
  }
  .bgBlur::before {
    transform: translate(0, 20%);
  }

  .defaultFullNew {
    padding-left: 16px;
    padding-right: 16px;
  }
  .lineCeap {
    line-height: 28px !important;
  }
  .iconCeap {
    float: none !important;

    margin: 0 !important;
  }
  .marginCeap {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  
  .comoRelaciona {
    margin-left: 15px !important;
    margin-right: 15px !important;
   }
 
 
 .caruselInsideCeap {
   width: 280px !important;
   margin: auto !important;
   flex-direction: column !important;
   
 }
 

.paddingCeap {
  padding-top: 20px !important;
}
.caruselCeap {
  width: 288px !important;
  margin: auto !important;
}

  .imgCaruselHomeNoResponsive {
    display: none !important;
  }
  .imgRecursosHome {
    display: none !important;
  }
  .imgCaruselHomeResponsive {
    width: 290px !important;
    display: inline-block !important;
  }
  .videoHomeResponsive {
    width: 290px !important;
    display: inline-block !important;
  }

  .titlePracticas {
    font-size: 0.875em !important;
  }

  .toolkitPregunta {
    font-size: 0.938em;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: white;
    padding: 18px;
  }

  .titleToolkit {
    font-size: 1.563em;
    font-weight: bold;
  }
  .descToolkit {
    padding-right: 10px !important;
  }
  .infofichad {
    font-size: 0.875em;
    font-weight: bold;
  }
  .bocadillo-cuadrado {
    float: none !important;

    padding-top: 15px;
    position: relative;
    height: 200px;
    width: 320px;
  }
  .bocadillo-cuadrado:before {
    content: "";
    border-top: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid transparent;
    position: absolute;
    top: 214px !important;
    left: calc(100% - 75px);
  }
  .bocclave1 {
    background: #3e7bbd;
  }
  .bocclave1:before {
    border-top: 20px solid #3e7bbd;
  }

  .bocclave2 {
    background: #4587c5;
  }
  .bocclave2:before {
    border-top: 20px solid #4587c5;
  }
  .bocclave3 {
    background: #60b471;
  }
  .bocclave3:before {
    border-top: 20px solid #60b471;
  }
  .bocclave4 {
    background: #75bb80;
  }
  .bocclave4:before {
    border-top: 20px solid #75bb80;
  }
  .bocclave5 {
    background: #88c493;
  }
  .bocclave5:before {
    border-top: 20px solid #88c493;
  }
  .bocclave6 {
    background: #986ca8;
  }
  .bocclave6:before {
    border-top: 20px solid #986ca8;
  }
  .bocclave7 {
    background: #db4b59;
  }
  .bocclave7:before {
    border-top: 20px solid #db4b59;
  }
  .bocclave8 {
    background: #df6868;
  }
  .bocclave8:before {
    border-top: 20px solid #df6868;
  }
  .bocclave9 {
    background: #e38077;
  }
  .bocclave9:before {
    border-top: 20px solid #e38077;
  }
  .bocclave10 {
    background: #d27e40;
  }
  .bocclave10:before {
    border-letopft: 20px solid #d27e40;
  }
}
@media only screen and (max-width: 420px) {



  .imgDownload {
    width: 350px !important;
  }
  .mPaddingR {
    margin-left: 90px !important;
    margin-top: 0px;
  }
  .mPaddingR2 {
    margin-left: 5px !important;
  }
  .borderPuntuaArriba {
    border-right: 0px dashed var(--grey-dark) !important;
  }
  .borderPuntua {
    border-right: 0px dashed var(--grey-dark) !important;
  }
  .paddingRecursos {
    padding-right: 20px;
    padding-left: 20px;
  }
  .hideTablet {
    display: none;
  }
  .titlePracticas {
    font-size: 0.875em !important;
  }

  .beneficio {
    float: left !important;
  }
  .rightd {
    font-size: 0.688em;
  }
  .itemHomeResp {
    padding-left: 17px !important;
    padding-right: 17px !important;
    padding-top: 12px !important;
    padding-bottom: 15px !important;
  }
  .makeStyles-right-120 {
    padding: 7px 0 !important;
  }
  .imgCenter {
    text-align: center !important;
  }
  footer ul li a {
    padding: 3px;
  }
  .footerLink {
    font-size: 8px !important;
  }
  .closeWindow {
    width: 34px;
  }
  .arrow-prev {
    width: 16px;
    height: 16px;
  }

  .arrow-next {
    width: 16px;
    height: 16px;
  }
  .menu-item-scroll {
    margin-right: 24px;
    width: 150px;
    height: 110px;
    background-size: 70px 70px;
  }
  .estrella {
    width: 70px;
    height: 70px;
  }
  .btnCenterResponsive {
    text-align: center;
  }
  .imgCaruselHomeResponsive {
    width: 290px !important;
    display: inline-block;
  }
  .videoHomeResponsive {
    width: 290px !important;
    display: inline-block !important;
  }
  .imgCaruselHomeNoResponsive {
    display: none;
  }
  .imgRecursosHome {
    display: none;
  }
  
  .carousel-control-next,
  .carousel-control-prev {
    display: none;
  }
  .imgCarrusel {
    height: 177px !important;
  }
  .navHeader {
    height: 60px !important;
  }

  .slider a.previousButton {
    left: 5px !important;
  }
  .slider a.nextButton {
    right: 5px !important;
  }
  .homeCarrusel {
    height: 560px !important;
    padding-left: 30px !important;
  }
  .btnRemove {
    height: 50px !important;
    width: 290px !important;
  }

  .btnHome {
    height: 50px !important;
    width: 290px !important;
  }
  .btnDisabled {
    height: 50px !important;
    width: 290px !important;
  }
  .btnFilter {
    width: 160px !important;
  }
  .widthContact {
    width: 260px !important;
    margin-bottom: 10px !important;
  }
  .homeContainer {
    padding: 10px !important;
  }
  .imgHomeVideo {
    width: 290px;
    height: 290px;
    margin-left: 15px;
  }
  .imgHome {
    width: 290px !important;
  }
  .marginBottom {
    margin-bottom: 20px;
  }
  .imgHomeXcelence {
    width: 290px !important;
  }
  .imgHomeComo {
    width: 290px !important;
  }
  .itemCaruselCeap {
    width: 280px !important;
    margin: auto !important;
  }
  

.imgCaruselCeap{
  width: 126px !important;
} 
  .logoHeader {
    margin-left: 10px !important;
    padding-top: 10px !important;
  }
  .logoHeader img {
    width: 115px !important;
  }
  .menuDrawer {
    display: none !important;
  }

  .fa-bars {
    top: 20px !important;
    right: 20px;
  }
  .menuDrawer {
    top: 20px !important;
    right: 50px;
  }
  .h1home {
    font-size: 1.25em !important;
  }
  .h1link {
    font-size: 1.25em !important;
  }
  .subh1home {
    font-size: 1em !important;
  }
  .titleToolkit {
    font-size: 1.5em !important;
  }
  .subtoolkit {
    font-size: 1em !important;
  }
  .toolkitPregunta {
    font-size: 1em !important;
  }
  .h2home {
    font-size: 1em !important;
  }
  .cornerHomeLeft {
    border-bottom-left-radius: 30px !important;
  }
  .cornerHomeRight {
    border-bottom-right-radius: 30px !important;
  }
  .footerbg {
    height: 50px !important;
  }
  .txtHome {
    font-size: 0.875em !important;
  }
  .txtHomelink {
    font-size: 0.875em !important;
  }
  .txtHomeLittle {
    font-size: 0.75em;
  }
  .txtHomeClaves {
    font-size: 0.75em;
  }
  .txtTestimonio {
    font-size: 0.688em !important;
  }
  .privacityp,
  div.MuiGrid-item.privacityp {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .textPrivacity {
    font-size: 0.75em;
    font-weight: 500;
    line-height: 1.33;
  }
  .fullPageNew {
    overflow-x: hidden;
  }
  .defaultFull {
    min-height: 100% !important;
    margin-top: 20px !important;
  }
  .defaultgrid {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .linkpriv {
    padding: 9px 0;
  }
  .marginSubTitle {
    margin-top: 20px;
  }
  .marginSubText {
    margin-top: 20px;
  }
  .nofull {
    width: 250px !important;
  }
  .marginGrid {
    margin-top: 10px;
  }
  .widthF {
    width: 270px !important;
  }
  .registerWidth {
    width: 270px !important;
  }
  .labelForm {
    font-size: 0.75em !important;
  }
  .labelForm .MuiFormControlLabel-label {
    font-size: 0.75em !important;
  }
  .widthContactForm {
    width: 270px !important;
  }
  .btnFullButton {
    width: 280px !important;
    height: 60px !important;
  }
  .sNoResponsive {
    display: none;
  }

  .sResponsive {
    display: inline-block;
  }
  .rounded {
    border-radius: 10px !important;
  }
  .carruselClaves {
    width: 330px !important;
    margin-left: 0px !important;
  }
  .imgListadoPracticas {
    width: 220px !important;
  }

  .marginLeftHome {
    margin-left: 10px;
  }
  .marginRightHome {
    margin-right: 0px;
  }
  .borderPuntuaArriba {
    border-right: 0px dashed var(--grey-dark) !important;
    display: none;
  }
  .borderPuntua {
    border-right: 0px dashed var(--grey-dark) !important;
  }
  .nomobile {
    display: none;
  }
  .yesmobile {
    display: block;
  }
  .padd10 {
    padding-right: 0px;
  }
  .marg10 {
    margin-top: 1px;
  }
  .marg15 {
    margin-top: 15px !important;
  }


  .sizeVideoCap {
    margin: auto !important;
    width: 300px !important;
    height: 169px !important;
  }
}
@media (min-width: 421px) and (max-width: 990px) {
  .hideTablet {
    display: none;
  }
}
